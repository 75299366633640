import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function InputLabel({ value, className = '', children, ...props }) {
    return (
        <label {...props} className={`form_group_label` + className}>
            {value ? value : children}
        </label>
    );
}
