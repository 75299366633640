import { forwardRef, useEffect, useRef } from 'react';
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const input = ref ? ref : useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <input
            {...props}
            type={type}
            className={
                className
            }
            ref={input}
        />
    );
});
